<template>
  <div id="wallet">
    <broadPage>
      <div slot="content">
        <div class="content">
          <p class="w-title">Connect to a Wallet</p>
          <div class="w-contnet mb-4" @click="connectBtn('matemask')" v-show="isMetamask">
            <div>
              Metamask
            </div>
            <img src="./images/mask.svg" />
          </div>
          <div class="w-contnet mb-4" v-show="!isMetamask" @click="linkMeta">
            <div>
              Install Metamask
            </div>
            <img src="./images/mask.svg" />
          </div>
          <div class="w-contnet mb-4" @click="connectBtn('wallet')">
            <div>
             WalletConnect
            </div>
            <img src="./images/connect.svg"/>
          </div>
          <div class="w-contnet mb-4" @click="connectBtn('coinbase')">
            <div>
              Coinbase Wallet
            </div>
            <img src="./images/coinbase.svg" />
          </div>
          <router-link to="/balances" class="btn btn-middle btn-primary mt-3"
            >Continue</router-link
          >
        </div>
      </div>
    </broadPage>
  </div>
</template>

<script>
import broadPage from "../../components/broadPage.vue";
import { connect, isMetamask} from "../../unitls"
export default {
  name: "wallet",
  components: { broadPage },
  data () {
    return {
      isMetamask
    }
  },
  methods: {
    connectBtn (val) {
      connect(val)
    },
    linkMeta () {
      window.open('https://metamask.io/')
    }
  },
};
</script>
<style lang="less" scoped>
#wallet {
  margin-top:140px;
  .content {
    padding: 30px;
    .w-title {
      font-family: ParmaPetit;
      font-size: 20px;
      color: #085da3;
      margin-bottom: 25px;
    }
    .w-contnet {
      display: flex;
      justify-content: space-between;
      padding: 17px 25px;
      border: 1px solid #e0e0e0;
      font-family: Louis George Cafe;
      font-size: 14px;
      color: #818e9e;
      cursor: pointer;
    }
    .btn {
      width: 100%;
    }
  }
}
</style>
